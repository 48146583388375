<template>
    <el-dialog :visible.sync="visible" width="500px" :before-close="handleClose">
        <el-image :src="image" class="modal-image"></el-image>
    </el-dialog>
</template>
  
<script>

export default {
    props: ['visible', 'image'],
    data() {
        return {
            displayImage : ''
        };
    },
    methods: {
        handleClose() {
            this.$emit('closeDialog')
        },
    },
};
</script>
  
<style scoped lang="scss">
.modal-image{
    max-width: 500px;
    object-fit: contain;
}
</style>
  