<template>
  <div>
    <!-- Header -->
    <div class="header bg_maroon py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon fill="#EDF0F5" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container pb-5" style="margin-top: -12rem">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-5 mt-5">
                <img src="/img/logo_new.svg" height="40" width="340" alt="">
              </div>
              <div class="text-center text-muted mb-5 mt-5 reset-text">
                <template v-if="this.patient_reset || programUserReset">
                  <h2>SET PASSWORD</h2>
                </template>
                <template v-else>
                  <h2> {{heading_text}}</h2>
                </template>
                <p v-if="this.patient_reset">Please create a password that you'll use to access the Baritastic app</p>
                <p v-if="programUserReset">Please create a password that you'll use to access your Baritastic account</p>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              type="password"
                              class="mb-3"
                              name="password"
                              :rules="{required: true, min:8}"
                              prepend-icon="ni ni-lock-circle-open"
                              placeholder="Password"
                              v-model="api_param.password">
                  </base-input>
                  <base-input alternative
                              type="password"
                              class="mb-3"
                              name="confirm password"
                              :rules="{required: true, min:8}"
                              prepend-icon="ni ni-lock-circle-open"
                              placeholder="Confirm Password"
                              v-model="api_param.password_confirmation">
                  </base-input>

                  <div class="text-center pb-0">
                    <base-button
                      :disabled="submitButton"
                      :loading="loader"
                      type="submit"
                      native-type="submit"
                      class="mt-4 mb-0 width_100 auth_orange_button">
                      Submit
                    </base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        submitButton: true,
        patient_reset : false,
        api_param: {
          password: null,
          password_confirmation: null,
          token: null,
          patient_id: null,
        },
        loader: false,
        programUserReset: false,
        heading_text : 'RESET PASSWORD',
      };
    },
    created() {
      this.api_param.patient_id = this.$route.query.id ?? null;
      if (this.api_param.patient_id != null){
        this.heading_text ='SET NEW PASSWORD';
        this.patient_reset = true;
      }

      //Condition to check id query params have isProgramUser
      if (this.$route.query.isProgramUser && this.$route.query.isProgramUser == 'yes') {
        this.programUserReset = true
      }

      this.verifyLink();
    },
    methods: {
      verifyLink(){
        this.api_param.token = this.$route.query.token;

        //Condition to add program user check
        if (this.programUserReset) {
          this.api_param.token = this.api_param.token.replaceAll(' ', '+')
          this.api_param.isProgramUserReset = true
        }

        axios.post(`${this.$store.getters.getBaseUrl}/api/verify-token`,
          this.api_param,
        ).then((response) => {
          this.$store.commit('toggleSessionCheck', false)
          this.$store.commit('updateSessionTime', 0)
          this.submitButton = false;
        }).catch(error => {
          this.submitButton = true;
          let message = 'Something went wrong please try again in few minutes.';
          if(error.response) {
            message = error.response.data.message;
          }
          this.loader = false;
          this.$notify.error({
            title: 'Error',
            message: message,
            duration: 0
          });
        });
      },
      checkAlphanumeric(password) {
        const letter = /[a-zA-Z]/;
        const number = /[0-9]/;
        const valid = number.test(password) && letter.test(password);
        return valid;
      },

      onSubmit() {
        this.api_param.token = this.$route.query.token;

        let vm = this;
        this.api_param.token = this.$route.query.token;
        if (!(this.checkAlphanumeric(this.api_param.password) && this.checkAlphanumeric(this.api_param.password_confirmation)))
        {
          vm.$notify.error({
            title: 'Error',
            message: 'Password must contain at least one character and number',
          })
          return
        }
        this.loader = true;
        let url = '';
        if(this.$route.name === 'PatientResetPassword') {
          url = `${this.$store.getters.getBaseUrl}/api/patient/reset-password`;
          this.api_param.email = this.$route.query.email;
        } else {

          //Condition to add program user check
          if (this.programUserReset) {
            this.api_param.isProgramUserReset = true
            this.api_param.token = this.api_param.token.replaceAll(' ', '+')
          }
          url = `${this.$store.getters.getBaseUrl}/api/reset-password`;
        }
        axios.post(url,
          this.api_param,
        ).then((response) => {
          vm.$store.commit('toggleSessionCheck', false)
          vm.$store.commit('updateSessionTime', 0)
          vm.loader = false;
          let message = 'Your password has been updated. ';
          message += (this.programUserReset) ? 'Please check your email.' : '';
          vm.$notify.success({
            title: 'Success',
            message: message
          });
          if(vm.patient_reset) {
            window.location = "https://www.baritastic.com/getting-started-with-baritastic/";
          }
          else {
            vm.$router.push({name: 'Login'});
          }
        }).catch(error => {
          let message = 'Something went wrong please try again in few minutes.';
          if(error.response) {
            message = error.response.data.message;
          }
          vm.loader = false;
          vm.$notify.error({
            title: 'Error',
            message: message,
          });
        })
        .finally(() => {
          vm.loader = false;
        });
      }
    }
  };
</script>
<style>
  .auth_orange_button:hover {
    color: white;
  }

  .el-notification__content p{
   text-align: left;
  }

  .reset-text * {
    color: #000000;
    opacity: 1;
    letter-spacing: 0;
  }

  .reset-text h2 {
    text-align: center;
    font: normal normal 600 20px/27px Open Sans;
    text-transform: uppercase;
  }

  .reset-text p {
    text-align: center;
    font: normal normal normal 16px/22px Open Sans;
  }
</style>
