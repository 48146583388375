import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    sourceGetAll: null,
  },
  getters: {},
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({ commit }) {
      commit("toggleSessionCheck", true, { root: true });
      commit("updateSessionTime", 0, { root: true });
    },
    /**
     * Get All Motivational Quotes
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    
    _getAllMotivationalQuote({ state, dispatch }, { params }) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAll){
          state.sourceGetAll.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios
          .get(`${state.base_url}/api/admin/motivational-quote`, { params,  cancelToken: state.sourceGetAll.token })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              resolve({});
            } else {
              // Handle other errors
              reject(error)
            }
          });
      });
    },
    
    /**
     * Get Motivational Quote By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getMotivationalQuoteById({ state, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.base_url}/api/admin/motivational-quote/show/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    /**
     * Store Motivational Quote
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

    _storeMotivationalQuote({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/motivational-quote/store`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /**
     * Update Motivational Quote
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

    _updateMotivationalQuote({ state, dispatch }, { fd,id }) {
        return new Promise((resolve, reject) => {
          axios
            .put(`${state.base_url}/api/admin/motivational-quote/update/${id}`, fd)
            .then((response) => {
              dispatch("_resetLogoutTimer");
              resolve(response);
            })
            .catch((error) => reject(error));
        });
    },
    /**
     * delete Motivational Quote By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteMotivationalQuoteById({ state, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${state.base_url}/api/admin/motivational-quote/destroy/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /**
     * delete multiple Motivational Quote by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteMultipleMotivationalQuote({ state, dispatch }, { ids }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`${state.base_url}/api/admin/motivational-quote/destroy-multiple`,{ids})
            .then((response) => {
              dispatch("_resetLogoutTimer");
              resolve(response);
            })
            .catch((error) => reject(error));
        });
    },
    /**
     * Toggle Status of multiple Motivational Quote by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _toggleStatusMultipleMotivationalQuote({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/motivational-quote/toggle-status-multiple`,fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};