import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    recipe: {
      user_id: 48,
      category_id: [],
      name: '',
      recipe: '',
      ingredient: '',
      picture: '',
      recipes_flag: 0,
      status: 0,
      nt_check: 0,
      item_id: 0,
      brand_id: '',
      item_name: '',
      brand_name: '',
      nf_calories: '',
      nf_total_fat: '',
      nf_saturated_fat: '',
      nf_trans_fatty_acid: '',
      nf_polyunsaturated_fat: '',
      nf_monounsaturated_fat: '',
      nf_cholesterol: '',
      nf_sodium: '',
      nf_sugars: '',
      nf_protein: '',
      nf_serving_size_qty: '',
      nf_serving_size_unit: '',
      nf_serving_weight_grams: '',
      nf_type: '',
      nf_totalItem: '',
      nf_dietary_fiber: '',
      nf_remote_db_key: '',
      nf_total_carbohydrate: '',
      nf_calcium: ''
    }
  },
  getters: {
    getRecipe(state) {
      return state.recipe
    }
  },
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },

    /**
     * Get all Recipes
     * @param context - Contains the commit and state object
     * @param params - Params for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _getAllRecipes({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/manage-recipes`, {params})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get Requested Recipes
     * @param context - Contains the commit and state object
     * @param params - Params for the request
     * @returns {Promise<unknown>}
     * @private
     */
    _getRequestedRecipe({state, dispatch}, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/manage-recipes/${params.id}`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Get all Categories
     * @param context - Contains the commit and state object
     * @returns {Promise<unknown>}
     * @private
     */
    _getAllCategories({state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(`${state.base_url}/api/admin/manage-recipes/category/all`)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Store Recipe
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _storeRecipe({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/manage-recipes/store`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Recipe
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _updateRecipe({state, dispatch}, {config, formData}) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/manage-recipes/update`, formData, {config})
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    /**
     * Update Recipe(s) status
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _updateStatusRecipe({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/manage-recipes/toggle-status`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },

    /**
     * Delete Recipe(s)
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * * @returns {Promise<unknown>}
     * @private
     */
    _deleteRecipe({state, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`${state.base_url}/api/admin/manage-recipes/delete`, payload)
          .then(response => {
            dispatch('_resetLogoutTimer')
            resolve(response)
          }).catch(error => {
          reject(error)
        })
      })
    },
  }
}
