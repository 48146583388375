import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    sourceGetAll: null,
  },
  getters: {},
  actions: {
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({ commit }) {
      commit("toggleSessionCheck", true, { root: true });
      commit("updateSessionTime", 0, { root: true });
    },
    /**
     * Get All Recommended Readings
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getAllRecommendedReadings({ state, dispatch }, { params }) {
      return new Promise((resolve, reject) => {
        if(state.sourceGetAll){
          state.sourceGetAll.cancel()
        }
        const CancelToken = axios.CancelToken;
        state.sourceGetAll = CancelToken.source();
        axios
          .get(`${state.base_url}/api/admin/recommended-readings`, { params,  cancelToken: state.sourceGetAll.token })
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              // Request was canceled
              resolve({});
            } else {
              // Handle other errors
              reject(error)
            }
          });
      });
    },
    /**
     * Get Recommended Reading By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _getRecommendedReadingsById({ state, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${state.base_url}/api/admin/recommended-readings/show/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    /**
     * Store Recommended Readings
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

    _storeRecommendedReadings({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/recommended-readings/store`, fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

      /**
     * Update Recommended Readings
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */

      _updateRecommendedReadings({ state, dispatch }, { fd,id }) {
        return new Promise((resolve, reject) => {
          axios
            .put(`${state.base_url}/api/admin/recommended-readings/update/${id}`, fd)
            .then((response) => {
              dispatch("_resetLogoutTimer");
              resolve(response);
            })
            .catch((error) => reject(error));
        });
      },
    /**
     * delete Recommended Reading By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteRecommendedReadingsById({ state, dispatch }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${state.base_url}/api/admin/recommended-readings/destroy/${id}`)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /**
     * delete multiple Recommended Reading by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _deleteMultipleRecommendedReadings({ state, dispatch }, { ids }) {
        return new Promise((resolve, reject) => {
          axios
            .post(`${state.base_url}/api/admin/recommended-readings/destroy-multiple`,{ids})
            .then((response) => {
              dispatch("_resetLogoutTimer");
              resolve(response);
            })
            .catch((error) => reject(error));
        });
     },
    /**
     * Toggle Status of multiple Recommended Reading by IDs
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _toggleStatusMultipleRecommendedReadings({ state, dispatch }, { fd }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${state.base_url}/api/admin/recommended-readings/toggle-status-multiple`,fd)
          .then((response) => {
            dispatch("_resetLogoutTimer");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
   },
  },
};
